import React from "react"
import { graphql, navigate } from "gatsby"
import { Helmet } from "react-helmet"
import get from "lodash/get"

import linkBuilder from "../../modules/linkBuilder"

/* Components */
import Layout from "../../components/layout"
import Seo from "../../components/seo/seo"
import SocialMeta from "../../components/social-meta/social-meta"
import StickyNav from "../../components/sticky-nav/sticky-nav"
import RichTextContent from "../../components/rich-text-content/rich-text-content"
import Footer from "../../components/footer/footer"
import { FaCheck, FaChevronRight } from "react-icons/fa"

/* Styles */
import {
  productMain,
  container,
  intro,
  introContent,
  title,
  subtitle,
  introDescription,
  priceDescription,
  introPrice,
  priceContainer,
  introImage,
  mediaObject,
  mediaObjectContent,
  mediaObjectTitle,
  mediaObjectDescription,
  mediaObjectImage,
  moreInfo,
  moreInfoContent,
  moreInfoTitle,
  moreInfoDescription,
  moreInfoForm,
  benefit,
  benefitTitle,
  benefitContent,
  benefitDescription,
  benefitList,
  benefitListItem,
  benefitListItemContent,
  benefitSubList,
  benefitSubListItem,
  stats,
  statsTitle,
  statsList,
  statsListItem,
  statsListItemValue,
  statsListItemLabel,
  statsContent,
  statsText,
  checkIcon,
  chevronRightIcon,
  contact,
  contactContent,
  contactTitle,
  contactDescription,
  contactForm,
  ctaContainer,
  ctaButton,
  ctaText,
  priceExVat,
} from "./product.module.css"
import { GatsbyImage } from "gatsby-plugin-image"
import DynamicFormBuilder from "../../components/dynamic-form-builder/dynamic-form-builder"
import { eventTracking } from "../../services/tracking"
import { trackEvent } from "../../modules/analytics"
import CustomForm from "../../components/custom-form/custom-form"

class ProductBetaTemplate extends React.Component {
  render() {
    const product = get(this.props, "data.contentfulProductBeta")

    const productDescription = get(
      product,
      "introDescription.childMarkdownRemark.rawMarkdownBody"
    )

    const desks = get(this.props, "pageContext.desks")
    const formBuilderData = get(product, "formBuilderCheckout")
    const moreInfoFormData = get(product, "moreInfoForm")

    const form1 = get(this.props, "pageContext.form1")
    const form2 = get(this.props, "pageContext.form2")

    const productUrl = `https://www.mkbservicedesk.nl${linkBuilder.product(
      product
    )}`

    const trackExternalLink = (data, position, parentData) => {
      trackEvent("cta_product")
      eventTracking("click", {
        contentfulId: data.contentful_id,
        contentfulType: "externalLink",
        contentfulTitle: data.title,
        displayPageContentfulId: parentData?.pageContentfulId,
        displayPageContentfulTitle: parentData?.pageContentfulTitle,
        displayPosition: position,
      })
    }

    return (
      <Layout location={this.props.location}>
        <Seo
          noIndex={product.noIndex || false}
          title={product.pageTitle || product.name}
          description={product.metaDescription || productDescription}
          canonicalUrl={product.canonicalUrl || productUrl}
        />
        <SocialMeta
          title={product.title}
          description={productDescription}
          image={
            product.introImage &&
            product.introImage.gatsbyImageData &&
            product.introImage.gatsbyImageData.images &&
            product.introImage.gatsbyImageData.images.fallback &&
            product.introImage.gatsbyImageData.images.fallback.src
          }
          url={productUrl}
        />
        <Helmet>
          <link rel="canonical" href={productUrl} />
        </Helmet>
        <header className="header">
          <StickyNav desks={desks} />
        </header>
        <main className={productMain}>
          <div className={container}>
            <div>
              <h1 className={title}>{product.name}</h1>
              <p className={subtitle}>{product.subtitle}</p>
            </div>
            {/* INTRO */}
            <div className={intro}>
              <div className={introContent}>
                <p className={introDescription}>{productDescription}</p>
                <div className={priceContainer}>
                  {product.price && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span className={introPrice}>€ {product.price}</span>
                      <span className={priceExVat}>
                        excl. btw (€ {(product.price * 1.21).toFixed(2)} incl.
                        btw)
                      </span>
                    </div>
                  )}
                  <div className={priceDescription}>
                    {product.priceDescription && (
                      <RichTextContent
                        content={product.priceDescription}
                        addListMarker={true}
                      />
                    )}
                  </div>
                </div>
                <div className={ctaContainer}>
                  {product.ctaLink ? (
                    <a
                      href={product.ctaLink.url}
                      title={product.ctaButtonText}
                      onClick={() => {
                        trackExternalLink(product.ctaLink, "intro", {
                          pageContentfulId: product.contentful_id,
                          pageTitle: product.title,
                        })
                      }}
                      rel={
                        product.ctaLink?.sponsored
                          ? "sponsored noreferrer noopener nofollow"
                          : "noreferrer noopener"
                      }
                      target="_blank"
                    >
                      <button className={ctaButton}>
                        {product.ctaButtonText}
                      </button>
                    </a>
                  ) : (
                    <button
                      onClick={() => {
                        trackEvent("cta_product_scroll")
                        navigate(
                          product.ctaButtonEvent ? "#meerinfo" : "#contact"
                        )
                      }}
                      className={ctaButton}
                    >
                      {product.ctaButtonText}
                    </button>
                  )}
                  {product.ctaButtonSubText && (
                    <span className={ctaText}>
                      {
                        product.ctaButtonSubText.childMarkdownRemark
                          .rawMarkdownBody
                      }
                    </span>
                  )}
                </div>
              </div>

              {product.introImage.gatsbyImageData && (
                <div style={{ flex: "1 0 0", width: "100%" }}>
                  <GatsbyImage
                    layout="fullWidth"
                    className={introImage}
                    image={product.introImage.gatsbyImageData}
                    alt={product.introImage.title ?? ""}
                  />
                </div>
              )}
              {product.introImage.gatsbyImageData === null &&
                product.introImage.file && (
                  <div style={{ flex: "1 0 0", width: "100%" }}>
                    <img
                      className={introImage}
                      src={product.introImage.file.url}
                      alt={product.introImage.title ?? ""}
                    />
                  </div>
                )}
            </div>
            {/* MEDIA OBJECT */}
            {product.mediaObjectTitle &&
              product.mediaObjectDescription &&
              product.mediaObjectImage && (
                <div className={mediaObject}>
                  {product.mediaObjectImage.gatsbyImageData && (
                    <div style={{ flex: "1 0 0", width: "100%" }}>
                      <GatsbyImage
                        layout="fullWidth"
                        className={mediaObjectImage}
                        imgClassName={mediaObjectImage}
                        image={product.mediaObjectImage.gatsbyImageData}
                        alt={product.mediaObjectImage.title ?? ""}
                      />
                    </div>
                  )}

                  {product.mediaObjectImage.gatsbyImageData === null &&
                    product.mediaObjectImage.file && (
                      <div style={{ flex: "1 0 0", width: "100%" }}>
                        <img
                          className={mediaObjectImage}
                          src={product.mediaObjectImage.file.url}
                          alt={product.mediaObjectImage.title ?? ""}
                        />
                      </div>
                    )}

                  <div className={mediaObjectContent}>
                    <h3 className={mediaObjectTitle}>
                      {product.mediaObjectTitle}
                    </h3>
                    <div className={mediaObjectDescription}>
                      <RichTextContent
                        content={product.mediaObjectDescription}
                      />
                    </div>
                  </div>
                </div>
              )}
            {/* MORE INFO */}
            {product.useMoreInfo &&
              product.moreInfoTitle &&
              product.moreInfoDescription && (
                <div className={moreInfo} id="meerinfo">
                  <div className={moreInfoContent}>
                    <h3 className={moreInfoTitle}>{product.moreInfoTitle}</h3>
                    <div className={moreInfoDescription}>
                      <RichTextContent
                        content={product.moreInfoDescription}
                        addListMarker={true}
                      />
                    </div>
                  </div>
                  <div className={moreInfoForm}>
                    {/* <ProductContactForm
                    contentfulId={product.contentful_id}
                    title={product.name}
                    trackEvent="ecommerce_contact"
                    mailingList={mailingList}
                  /> */}
                    {form1 && <CustomForm formData={form1} formPosition="product_page_top" />}
                    {moreInfoFormData.rows &&
                      moreInfoFormData.rows.length > 0 && (
                        <DynamicFormBuilder
                          formData={moreInfoFormData}
                          asset={product.formBuilderDownload}
                          productId={product.contentful_id}
                          productName={product.name}
                          productSubtitle={product.subtitle}
                          formType="contact"
                          formIndex="1"
                          productPrice={product.price}
                        />
                      )}
                  </div>
                </div>
              )}
            {/* BENEFITS */}
            {product.benefitTitle &&
              product.benefitDescription &&
              product.benefitList && (
                <div className={benefit}>
                  <div className={benefitContent}>
                    <h3 className={benefitTitle}>{product.benefitTitle}</h3>
                    <div className={benefitDescription}>
                      <RichTextContent
                        content={product.benefitDescription}
                        addListMarker={true}
                      />
                      {product.ctaLink ? (
                        <a
                          href={product.ctaLink.url}
                          title={product.ctaButtonText}
                          onClick={() => {
                            trackExternalLink(product.ctaLink, "benefits", {
                              pageContentfulId: product.contentful_id,
                              pageTitle: product.title,
                            })
                          }}
                          rel={
                            product.ctaLink?.sponsored
                              ? "sponsored noreferrer noopener nofollow"
                              : "noreferrer noopener"
                          }
                          target="_blank"
                        >
                          <button className={ctaButton}>
                            {product.ctaButtonText}
                          </button>
                        </a>
                      ) : (
                        <button
                          onClick={() => {
                            navigate(
                              product.ctaButtonEvent ? "#meerinfo" : "#contact"
                            )
                          }}
                          className={ctaButton}
                        >
                          {product.ctaButtonText}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className={benefitList}>
                    {product.benefitList.list.map((item) => {
                      return (
                        <div key={item.label} className={benefitListItem}>
                          <FaCheck className={checkIcon} />
                          <div className={benefitListItemContent}>
                            <span>{item.label}</span>
                            {item.list.length > 0 && (
                              <div className={benefitSubList}>
                                {item.list.map((subItem) => {
                                  return (
                                    <div
                                      key={`${item.label}-${subItem}`}
                                      className={benefitSubListItem}
                                    >
                                      <FaChevronRight
                                        className={chevronRightIcon}
                                      />
                                      <span>{subItem}</span>
                                    </div>
                                  )
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            {/* STATS */}
            {product.statsTitle &&
              product.statsDescription &&
              product.statsSecondDescription &&
              product.statsItems.length > 0 && (
                <div className={stats}>
                  <h3 className={statsTitle}>{product.statsTitle}</h3>
                  <div className={statsList}>
                    {product.statsItems.map((item) => {
                      let [value, label] = item.split(";")
                      return (
                        <div key={label} className={statsListItem}>
                          <span className={statsListItemValue}>{value}</span>
                          <span className={statsListItemLabel}>{label}</span>
                        </div>
                      )
                    })}
                  </div>
                  <div className={statsContent}>
                    <div className={statsText}>
                      <RichTextContent
                        content={product.statsDescription}
                        addListMarker={true}
                      />
                    </div>
                    <div className={statsText}>
                      <RichTextContent
                        content={product.statsSecondDescription}
                        addListMarker={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            {/* CONTACT */}
            <div className={contact} id="contact">
              {product.ctaTitle && product.ctaDescription && (
                <div className={contactContent}>
                  <h3 className={contactTitle}>{product.ctaTitle}</h3>
                  <div className={contactDescription}>
                    <RichTextContent
                      content={product.ctaDescription}
                      addListMarker={true}
                    />
                  </div>
                </div>
              )}
              <div className={contactForm}>
                {form2 && <CustomForm formData={form2} formPosition="product_page_bottom" />}
                {product.useFormBuilder && (
                  <DynamicFormBuilder
                    formData={formBuilderData}
                    asset={product.formBuilderDownload}
                    productId={product.contentful_id}
                    productName={product.name}
                    productSubtitle={product.subtitle}
                    formType="checkout"
                    formIndex="2"
                    productPrice={product.price}
                  />
                )}
              </div>
              {product.ctaFormEmbed && (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      product.ctaFormEmbed.childMarkdownRemark.rawMarkdownBody,
                  }}
                  className={contactForm}
                />
              )}
            </div>
          </div>
        </main>
        <Footer />
      </Layout>
    )
  }
}

export default ProductBetaTemplate

export const pageQuery = graphql`
  query ProductBySlug($slug: String!) {
    contentfulProductBeta(slug: { eq: $slug }) {
      slug
      contentful_id
      name
      productType
      useMoreInfo
      ctaLink {
        contentful_id
        url
        sponsored
      }
      useFormBuilder
      ctaButtonEvent
      formBuilderDownload {
        id
        file {
          url
          contentType
        }
      }
      price
      pageTitle
      metaDescription
      canonicalUrl
      noIndex
      priceDescription {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            contentful_id
            __typename
            file {
              url
            }
          }
          ... on ContentfulExternalLink {
            contentful_id
            url
          }
        }
      }
      subtitle
      introDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      introImage {
        title
        gatsbyImageData(layout: FULL_WIDTH)
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
      mediaObjectTitle
      mediaObjectDescription {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            contentful_id
            __typename
            file {
              url
            }
          }
          ... on ContentfulExternalLink {
            contentful_id
            url
          }
        }
      }
      mediaObjectImage {
        title
        gatsbyImageData(layout: FULL_WIDTH)
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
      moreInfoTitle
      moreInfoDescription {
        raw
        references {
          __typename
          ... on ContentfulExternalLink {
            contentful_id
            url
          }
        }
      }
      moreInfoForm {
        id
        buttonText
        submitEvent
        submitMessageTitle
        submitMessage
        useMollie
        useMollieTest
        mollieEmailList
        rows {
          id
          inputFields {
            id
            isRequired
            hubspotValidation
            label
            type
            items
          }
        }
      }
      moreInfoFormEmail
      benefitTitle
      benefitDescription {
        raw
        references {
          __typename
          ... on ContentfulExternalLink {
            contentful_id
            url
          }
        }
      }
      benefitList {
        list {
          label
          list
        }
      }
      statsTitle
      statsDescription {
        raw
        references {
          __typename
          ... on ContentfulExternalLink {
            contentful_id
            url
          }
        }
      }
      statsSecondDescription {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            contentful_id
            title
            gatsbyImageData(layout: CONSTRAINED, width: 250, quality: 100)
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          ... on ContentfulExternalLink {
            contentful_id
            url
          }
        }
      }
      statsItems
      ctaTitle
      ctaDescription {
        raw
        references {
          __typename
          ... on ContentfulExternalLink {
            contentful_id
            url
          }
        }
      }
      ctaButtonText
      ctaButtonSubText {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      ctaFormEmbed {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      formBuilderCheckout {
        id
        buttonText
        submitEvent
        submitMessageTitle
        submitMessage
        useMollie
        useMollieTest
        mollieEmailList
        rows {
          id
          inputFields {
            id
            isRequired
            hubspotValidation
            label
            type
            items
          }
        }
      }
    }
  }
`
